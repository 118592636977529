import React from "react";
import {
  Select as MaterialSelect,
  InputLabel,
  FormControl,
  FormHelperText
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  formControl: {
    width: "100%",
  },
  input:{
    '&:before': {
      borderBottom: "2px solid #af0061",
    },
  }
}));
export default function Select(props) {
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(props.label ? inputLabel.current.offsetWidth : 0);
  }, [props]);
  return (
    <FormControl
      variant={props.variant ? props.variant : "outlined"}
      className={classes.formControl}
    >
      {props.label && <InputLabel ref={inputLabel} >{props.label}</InputLabel>}
      <MaterialSelect
        error={props.error}
        labelWidth={labelWidth}
        value={props.value}
        onChange={props.onChange}
        startAdornment={props.adornment}
        className={props.classes}
        inputProps={props.inputProps}
        classes={props.classes}
      >
        {props.children}
      </MaterialSelect>
      {props.helperText ? (
        <FormHelperText>{props.helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
}
