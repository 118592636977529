import urls from "../urls";

const getSport = async () => {
  const request = await fetch(urls.sports.get);
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};

const sportServices = Object.freeze({
  getSport,
});

export default sportServices;
