import React, { useContext, useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  ButtonSpinner,
  LoginFrom,
  SingUpForm,
  VerificationForm,
  ResetPasswordRequestForm,
  ResetPasswordForm,
  ResendEmailValidationForm,
  BurgerMenu,
} from ".";
import { AuthContext, ModalContext } from "../contexts";
import { useHistory } from "react-router-dom";
import { Typography } from "@mui/material";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "#2b4a3b",
    height: "15vh",
  },
  menu: {
    height: "100%",
  },
  box: {
    padding: "0 30px",
    cursor: "pointer",
  },
  button: {
    fontSize: "13px",
    fontWeight: "800",
    textTransform: "none",
    color: "#2a493a;",
  },
  listTitle: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  menutext: {
    fontSize: "13px",
    fontWeight: "700",
    color: "rgba(95, 95, 95, 1)",
  },
  [theme.breakpoints.down("md")]: {
    toolbar: {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
}));

export default function MyAppBar(props) {
  const classes = useStyles();
  const [openSingup, setOpenSingup] = useState(false);
  const [openValidateUser, setOpenValidateUser] = useState(false);
  const [openResetPasswordRequest, setOpenResetPasswordRequest] =
    useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [openResendToken, setOpenResendToken] = useState(false);
  const { signOut } = useContext(AuthContext);
  const { stateModal, open, close } = useContext(ModalContext);
  const [isAuth, setIsAuth] = useState(false);
  let history = useHistory();
  let query = useQuery();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const user = localStorage.getItem("user");
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const capitalizeWords = (str) => {
    if (!str) {
      return ""; // or return str; depending on how you want to handle null values
    }

    const words = str.split(" ");
    const capitalizedWords = words.map((word) => capitalizeFirstLetter(word));
    return capitalizedWords.join(" ");
  };

  useEffect(() => {
    const code = query.get("verificar");
    if (code) {
      setOpenValidateUser(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const code = query.get("code");
    if (code) {
      setOpenResetPassword(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuth(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const logOut = async () => {
    signOut();
    setIsAuth(false);
    history.push(`/`);
  };
  const NoAuth = (props) => {
    return (
      <Grid item xs={7} sm={10}>
        <Grid
          container
          // spacing={1}
          // justifyContent={"center"}
          justifyContent="flex-end"
          alignItems="center"
          style={{
            height: "100%",
            padding: "10px 4px",
            display: "flex",
            gap: 10,
          }}
        >
          {matches ? (
            <Grid item>
              <BurgerMenu
                isAuth={false}
                logOut={logOut}
                openLogin={open}
                openRegister={() => setOpenSingup(true)}
              />
            </Grid>
          ) : (
            <>
              <Grid
                item
                style={{
                  padding: "0 10px",
                  display: "flex",
                  gap: 20,
                  marginRight: 20,
                }}
              >
                {/* <Link
              to="/contactanos"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              Contáctanos
            </Link> */}
              </Grid>
              {/*  <Link
                to="/contacto"
                style={{
                  textDecoration: "none",
                  color: "#f9e189",
                }}
              >
                Contáctanos
              </Link> */}
              <Grid item>
                <ButtonSpinner
                  onClick={open}
                  style={{
                    width: "160px",
                    borderRadius: "10px",
                    backgroundColor: "#f9e189",
                  }}
                  className={classes.button}
                  color="default"
                  disableElevation
                >
                  Inicio de Sesión
                </ButtonSpinner>
              </Grid>
              <Grid item>
                <ButtonSpinner
                  onClick={() => {
                    setOpenSingup(true);
                  }}
                  style={{
                    width: "140px",
                    borderRadius: "10px",
                    backgroundColor: "#f9e189",
                  }}
                  className={classes.button}
                  color="default"
                  disableElevation
                >
                  Registro
                </ButtonSpinner>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    );
  };
  const AuthMenu = () => {
    return (
      <Grid item xs={7} sm={10}>
        <Grid
          container
          // spacing={1}
          // justifyContent={"center"}
          justifyContent="flex-end"
          alignItems="center"
          style={{ height: "100%", padding: "10px 4px" }}
        >
          {matches ? null : (
            <Grid
              item
              style={{
                padding: "0 10px",
                display: "flex",
                gap: 20,
                marginRight: 20,
              }}
            >
              {/*  <Link
                to="/contacto"
                style={{
                  textDecoration: "none",
                  color: "#f9e189",
                }}
              >
                Contáctanos
              </Link> */}
              <Typography style={{ color: "rgb(249, 225, 137)" }}>
                Bienvenido Sr(a) {capitalizeWords(user)}{" "}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <ButtonSpinner
              // onClick={open}
              onClick={() => history.push("/reservar")}
              style={{
                width: "100px",
                borderRadius: "10px",
                backgroundColor: "#f9e189",
              }}
              className={classes.button}
              color="default"
              disableElevation
            >
              Reservar
            </ButtonSpinner>
          </Grid>
          <Grid item>
            <BurgerMenu
              isAuth={true}
              logOut={logOut}
              openLogin={open}
              openRegister={() => setOpenSingup(true)}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <AppBar position="relative" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container justifyContent="space-between">
          <Grid item md={2} xs={2}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <Link to="/">
                <img
                  src="./img/logo.svg"
                  alt=""
                  width={matches ? 100 : 150}
                  height={matches ? 90 : 103}
                />
              </Link>
            </Grid>
          </Grid>
          {isAuth ? <AuthMenu /> : <NoAuth />}
        </Grid>
        <LoginFrom
          open={stateModal.open}
          auth={() => setIsAuth(true)}
          handleClose={() => close()}
          openSingup={() => setOpenSingup(true)}
          openResetPasswordRequest={() => setOpenResetPasswordRequest(true)}
          openResendToken={() => setOpenResendToken(true)}
        />
        <SingUpForm
          open={openSingup}
          handleClose={() => setOpenSingup(false)}
          height="600px"
          openActivate={() => setOpenValidateUser(true)}
        />
        <VerificationForm
          openResendToken={() => setOpenResendToken(true)}
          open={openValidateUser}
          handleClose={() => setOpenValidateUser(false)}
        />
        <ResetPasswordRequestForm
          open={openResetPasswordRequest}
          handleClose={() => setOpenResetPasswordRequest(false)}
          openResetPassword={() => setOpenResetPassword(true)}
        />
        <ResetPasswordForm
          open={openResetPassword}
          handleClose={() => setOpenResetPassword(false)}
          openLogin={() => open()}
        />
        <ResendEmailValidationForm
          open={openResendToken}
          handleClose={() => setOpenResendToken(false)}
          openActivate={() => setOpenValidateUser(true)}
        />
      </Toolbar>
    </AppBar>
  );
}
