import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Grid, IconButton } from "@material-ui/core/";
import CancelIcon from "@material-ui/icons/Cancel";
function getModalStyle() {
  const top = 20;
  const left = 50;

  return {
    top: `28%`,
    left: `${left}%`,
    transform: `translate(-${left}%, -${top}%)`,
  };
}

const useStyles = ({ height, border }) =>
  makeStyles((theme) => ({
    paper: {
      position: "absolute",
      overflowY: "scroll",
      borderRadius: "15px",
      outline: "none",
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "8px",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.1)",
        borderRadius: "10px",
      },
      width: 317,
      backgroundColor: theme.palette.background.paper,
      border: border ? "none" : "2px solid #fff",
      boxShadow: "0px 6px 14px rgba(198, 198, 198, 0.5)",
      padding: theme.spacing(2),
      paddingBottom: "40px",
      height: height,
      borderRight: border ? "25px solid #2b4a3b" : "none",
      borderLeft: border ? "25px solid #2b4a3b" : "none",
    },
  }));

export default function MyModal(props) {
  const classes = useStyles({ ...props })();
  const [modalStyle] = React.useState(getModalStyle);
  return (
    <Modal
      open={props.open}
      // disableBackdropClick={true}
      onClose={props.handleClose}
    >
      <Grid style={modalStyle} container className={classes.paper}>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item xs={1}>
              <IconButton onClick={props.handleClose} style={{ padding: 0 }}>
                <CancelIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <img src="./img/logo-negro.svg" alt="" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </Modal>
  );
}
