import React from "react";
import { Grid, Typography } from "@material-ui/core/";
import Link from "@material-ui/core/Link";
export default function Copyright() {
  return (
    <Grid
      container
      justifyContent="center"
      style={{ background: "rgba(133, 170, 129, 1)", padding: "10px" }}
    >
      <Typography variant="body2" align="center" style={{ color: "#fff" }}>
        {"Copyright © "}
        <Link target="_blank" color="inherit" href="https://back9.com.ve">
          Back9 solutions LLC.
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Grid>
  );
}
