import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
  Button,
  IconButton,
  TextField,

  // Grid,
} from "@material-ui/core";
import { BannerLayOut, Spinner } from "../components";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik/dist";
import * as yup from "yup";
import classes from "../components/contact/contact.module.css";
import { useSnackbar } from "notistack";
import { CloseOutlined } from "@mui/icons-material";
import urls from "../urls";
export default function Contacto() {
  const MuiTheme = useTheme();
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const sendEmail = async (data) => {
    try {
      setLoading(true);
      const res = await fetch(urls.contactMail, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) await Promise.reject(res);

      enqueueSnackbar("Su mensaje ha sido enviado con exito.", {
        preventDuplicate: true,
        autoHideDuration: 10000,
        variant: "success",
        action: (key) => {
          return (
            <IconButton onClick={() => closeSnackbar(key)}>
              <CloseOutlined />
            </IconButton>
          );
        },
      });

      document.querySelector("#hero-button").scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    } catch (error) {
      console.log({ error });
      enqueueSnackbar(error.message ?? "A ocurrido un error inesperado.", {
        preventDuplicate: true,
        autoHideDuration: 10000,
        variant: "error",
        action: (key) => {
          return (
            <IconButton onClick={() => closeSnackbar(key)}>
              <CloseOutlined />
            </IconButton>
          );
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      validationSchema: yup.object().shape({
        name: yup.string().required("Campo requerido."),
        email: yup.string().required("Campo requerido."),
        phone: yup.string().required("Campo requerido."),
        message: yup.string().required("Campo requerido."),
      }),
      onSubmit: ({ name, email, phone, message }) => {
        sendEmail({
          name,
          email,
          phone,
          message,
        });
      },
    });

  return (
    <>
      <Spinner loading={loading} />
      <BannerLayOut src="./img/banner.jpg">
        <Grid className={classes.flex}>
          <Grid>
            <Grid>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3926.774768891379!2d-64.6724375!3d10.198937500000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2d752dd71a1e7d%3A0x517e6a4e3c0acdc1!2sClub%20Golf%20Las%20Salina!5e0!3m2!1ses!2sve!4v1688398229316!5m2!1ses!2sve"
                className={classes.mapa}
              ></iframe>
            </Grid>
          </Grid>
          <Grid
            component="form"
            onSubmit={handleSubmit}
            style={{ maxWidth: "32rem" }}
          >
            <Grid item mb="1rem" width="12rem !important">
              <TextField
                style={{ background: "#fff" }}
                className={classes.formMessageContact}
                label="Nombre Completo"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                InputProps={{ disableUnderline: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item mb="1rem" width="12rem !important">
              <TextField
                style={{ background: "#fff" }}
                className={classes.formMessageContact}
                label="Correo electrónico"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                InputProps={{ disableUnderline: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item mb="1rem" width="12rem !important">
              <TextField
                style={{ background: "#fff" }}
                className={classes.formMessageContact}
                label="Teléfono"
                name="phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone && errors.phone}
                InputProps={{ disableUnderline: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item mb="1rem" xs={12}>
              <TextField
                style={{ background: "#fff" }}
                className={classes.formMessageContact}
                label="¿Cómo podemos ayudarte?"
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                error={touched.message && Boolean(errors.message)}
                helperText={touched.message && errors.message}
                InputProps={{ disableUnderline: true }}
                type="textarea"
                multiline
                variant="outlined"
                rows="3"
              />
            </Grid>
            <Grid item mb="1rem" xs={12} md={12}>
              <div className={classes.buttonContainerCU}>
                <Button
                  style={{
                    background: "#2b4a3b",
                    color: "#fff",
                    width: "200px",
                  }}
                  type="submit"
                >
                  Enviar
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </BannerLayOut>
    </>
  );
}
