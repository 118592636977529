import React, { useState } from "react";
import { Grid, Typography, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import { ButtonSpinner } from ".";
export default function ModalTourCodeError(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  return (
    <Modal
      open={props.open}
      onClose={() => props.close()}
      disableBackdropClick={true}
    >
      <Grid
        container
        justifyContent="center"
        style={modalStyle}
        className={classes.paper}
      >
        <Grid item md={12} xs={12} style={{ backgroundColor: "#FFF" }}>
          <Grid container justifyContent="flex-end">
            <CancelIcon
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={props.close}
            />
          </Grid>
          <Grid className={classes.containerimg} >
          <div style={{    display: 'flex', 'justify-content': 'center'}}>
            <img className={classes.img} src="./img/iso-la-salina.png" alt="" />
            </div>
          </Grid>
          <br />
          <Typography align="center" className={classes.modalText}>
            {props.message}
          </Typography>
          <br />
          <br />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <ButtonSpinner
              variant="contained"
              color={"primary"}
              onClick={() => props.close()}
            >
              <label className={classes.aceptButtonLabel}>{"Aceptar"}</label>
            </ButtonSpinner>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 317,
    height: 361,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #FFF",
    boxShadow: theme.shadows[5],
    padding: "20px",
    borderRadius: "20px",
    outline: "none",
  },
  paper2: {
    position: "absolute",
    width: 400,
    height: 220,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #FFF",
    boxShadow: theme.shadows[5],
    padding: "20px",
    borderRadius: "20px",
    outline: "none",
  },
  closeButton: {
    position: "absolute",
    zIndex: 10,
    top: "-4%",
  },
  aceptButtonLabel: {
    color: "#ffffff",
    fontSize: "0.8rem",
  },
  h2: {
    color: "#777777",
    fontWeight: "unset",
    fontSize: "1rem",
  },
  img: {
    height: 150,
    width: 150,
  },
  modalText: {
    color: "rgba(15, 16, 49, 1)",
    fontSize: "16px",
    fontWeight: 700,
  },
  p: {
    fontSize: "1.3rem",
    color: "#848484",
  },
  highlightedP: {
    fontSize: "1.5rem",
    color: "#af0061",
  },
  [theme.breakpoints.down("xs")]: {
    closeButton: {
      left: "90%",
    },
  },
  [theme.breakpoints.up("xs")]: {
    closeButton: {
      left: "92.5%",
    },
  },
  [theme.breakpoints.down("sm")]: {
    h2: {
      fontSize: "1.1rem",
    },
    p: {
      fontSize: "1rem",
    },
    highlightedP: {
      fontSize: "1.2rem",
    },

    containerimg: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));
