import React, { useContext } from "react";
import { BannerLayOut } from "../components";
import {
  makeStyles,
  Container,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import { format } from "date-fns";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { LoaderContext, AuthContext } from "../contexts";
import { reservationServices } from "../services";

function Resume() {
  const location = useLocation();

  const {
    state: { sport = "", selectedDate = new Date(), selectedSchedule = [] },
  } = location;

  const classes = useStyles();
  const history = useHistory();
  const { loadingActive, loadingDisabled } = useContext(LoaderContext);
  const { signOut } = useContext(AuthContext);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const reserve = async () => {
    try {
      loadingActive();
      const token = localStorage.getItem("token");
      const date = format(selectedDate, "yyyy-MM-dd");
      const schedules = selectedSchedule.map((schedule) => ({
        scheduleId: schedule.scheduleId,
        date,
      }));
      const { response, status } = await reservationServices.addReservation(
        schedules,
        token
      );
      console.log({ response, status });
      if (status === 200) {
        history.push("/metodos-de-pago", {
          bookingId: response.order.bookingId,
        });
      } else if (status === 401) {
        signOut();
        history.push(`/`);
      }
    } catch (error) {
      console.log({ error });
    } finally {
      loadingDisabled();
    }
  };

  return (
    <BannerLayOut src="./img/banner.jpg">
      <Container maxWidth="md">
        <Typography
          variant="h2"
          className={`${classes.title} ${classes.font}`}
          color="primary"
          align="center"
          gutterBottom
        >
          RESUMEN
        </Typography>
        <Grid container spacing={8}>
          <Grid item xs={12} md={6}>
            <div
              style={{ padding: 40, backgroundColor: "#FFF", borderRadius: 15 }}
            >
              <div
                style={{
                  padding: 40,
                  backgroundColor: "#F1F2F2",
                  borderRadius: "inherit",
                }}
              >
                <Typography
                  align="center"
                  variant="h4"
                  gutterBottom
                  color="primary"
                >
                  {sport.name}
                  {/* {(sport.charAt(0).toUpperCase() + sport.slice(1)).slice(
                    0,
                    -1
                  )}{" "}
                  {sport.match(/\d+/g)} */}
                </Typography>
                <Typography align="center" variant="h5">
                  DÍA: {format(selectedDate, "dd/MM/yyyy")}
                </Typography>
                <Typography align="center" variant="h5">
                  CANCHA: 1
                </Typography>
                {/* <div style={{ textAlign: "center" }}>
                  <Typography variant="h5" style={{ display: "inline" }}>
                    HORAS:{" "}
                  </Typography>
                  <div
                    style={{ display: "inline-flex", flexDirection: "column" }}
                  >
                    {selectedSchedule
                      .sort((firstEl, secondEl) =>
                        firstEl.hour < secondEl.hour ? -1 : 1
                      )
                      .map((schedule, index) => (
                        <Typography variant="h5" key={index}>
                          {schedule.hourRef.start} - {schedule.hourRef.end}
                        </Typography>
                      ))}
                  </div>
                </div> */}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 40,
            }}
          >
            <div
              style={{
                padding: 40,
                backgroundColor: "#FFF",
                borderRadius: 15,
              }}
            >
              <Typography variant="h2" align="center" className={classes.font}>
                {selectedSchedule.reduce(
                  (acc, current) => current.price + acc,
                  0
                )}{" "}
                $
              </Typography>
            </div>
            <Button
              className={`${classes.acceptButton} ${classes.font}`}
              variant="contained"
              color="primary"
              size="large"
              onClick={reserve}
            >
              Acepto
            </Button>
          </Grid>
        </Grid>
      </Container>
    </BannerLayOut>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontWeight: 700,
  },
  font: {
    fontFamily: ["Roboto Slab", "serif"].join(","),
  },
  acceptButton: {
    fontSize: "1.4rem",
    borderRadius: 50,
  },
}));

export default Resume;
