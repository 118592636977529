import React, { useState } from "react";
import { Modal, TextField, ButtonSpinner, Toast } from ".";
import { Grid, Typography, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { FormValidator } from "../helpers";
import { userServices } from "../services/";
const useStyles = makeStyles((theme) => ({
  textTitle: {
    color: "rgba(15, 16, 49, 1)",
    fontSize: "16px",
    fontWeight: 700,
  },
  top: {
    marginTop: "32px",
  },
}));

export default function SingUpForm(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [document, setDocument] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [seePassword, setSeePassword] = useState(false);
  const [seeConfirmPassword, setSeeConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [canRedirect, setCanRedirect] = useState(false);
  const passwordMatch = (confirmation) => password === confirmation;
  const passwordValidate = (password) => {
    const regex = new RegExp(
      "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
    );
    if (regex.test(password)) {
      return true;
    }
    return false;
  };
  const validator = new FormValidator([
    {
      field: "firstName",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese su nombre",
    },
    {
      field: "lastName",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese su apellido",
    },
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese su correo electrónico",
    },
    {
      field: "email",
      method: "isEmail",
      validWhen: true,
      message: "Ingrese un correo electrónico valido",
    },
    {
      field: "password",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese una contraseña",
    },
    {
      field: "document",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese su documento de identidad",
    },
    {
      field: "document",
      method: "isLength",
      validWhen: true,
      args: [{ min: 6, max: 8 }],
      message: "El número de documento debe contener entre 6 y 8 caracteres.",
    },
    {
      field: "phone",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese su número de teléfono",
    },
    {
      field: "phone",
      method: "isLength",
      validWhen: true,
      args: [{ min: 10, max: 12 }],
      message: "El número de telefono debe contener 10 números.",
    },
    {
      field: "password",
      method: passwordValidate,
      validWhen: true,
      message: "La contraseña debe tener mínimo 5 caracteres y un número",
    },
    {
      field: "confirmPassword",
      method: "isEmpty",
      validWhen: false,
      message: "Confirme su contraseña",
    },
    {
      field: "confirmPassword",
      method: passwordMatch,
      validWhen: true,
      message: "Las contraseñas no coinciden",
    },
  ]);
  const singUp = async () => {
    const validation = validator.validate({
      firstName,
      lastName,
      email,
      password,
      document,
      phone,
      confirmPassword,
    });
    setErrors(validation);
    if (validation.isValid) {
      setLoading(true);
      const { response, status } = await userServices.register({
        email,
        password,
        firstName,
        lastName,
        phone,
        document,
      });
      setLoading(false);
      if (status === 200) {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setPassword("");
        setDocument("");
        setConfirmPassword("");
        setMessage(
          "Usuario registrado, verifique su correo para validar su correo"
        );
        setOpenToast(true);
        setCanRedirect(true);
      } else {
        setMessage(response.error.message);
        setOpenToast(true);
      }
    }
  };
  const validateField = (e) => {
    const validation = validator.validateField(e.target.name, e.target.value);
    setErrors({
      ...errors,
      ...validation,
    });
  };
  return (
    <Modal {...props} border>
      <Grid container className={classes.top} justifyContent="center">
        <Typography className={classes.textTitle} align="center">
          Registro
        </Typography>
      </Grid>
      <Grid
        container
        className={classes.top}
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <TextField
            placeholder="Nombre"
            variant="outlined"
            name="firstName"
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={errors.firstName ? errors.firstName.isInvalid : false}
            helperText={errors.firstName ? errors.firstName.message : ""}
            onBlur={validateField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="Apellido"
            variant="outlined"
            name="lastName"
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={errors.lastName ? errors.lastName.isInvalid : false}
            helperText={errors.lastName ? errors.lastName.message : ""}
            onBlur={validateField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="Correo electrónico"
            variant="outlined"
            name="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errors.email ? errors.email.isInvalid : false}
            helperText={errors.email ? errors.email.message : ""}
            onBlur={validateField}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={6}>
              <TextField
                placeholder="Teléfono"
                variant="outlined"
                name="phone"
                fullWidth
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                error={errors.phone ? errors.phone.isInvalid : false}
                helperText={errors.phone ? errors.phone.message : ""}
                onBlur={validateField}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder="Documento"
                variant="outlined"
                name="document"
                fullWidth
                value={document}
                onChange={(e) => setDocument(e.target.value)}
                error={errors.document ? errors.document.isInvalid : false}
                helperText={errors.document ? errors.document.message : ""}
                onBlur={validateField}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="Contraseña"
            variant="outlined"
            name="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={seePassword ? "text" : "password"}
            error={errors.password ? errors.password.isInvalid : false}
            helperText={errors.password ? errors.password.message : ""}
            onBlur={validateField}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSeePassword(!seePassword)}
                >
                  {!seePassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="Confirmar contraseña"
            variant="outlined"
            name="confirmPassword"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type={seeConfirmPassword ? "text" : "password"}
            error={
              errors.confirmPassword ? errors.confirmPassword.isInvalid : false
            }
            helperText={
              errors.confirmPassword ? errors.confirmPassword.message : ""
            }
            onBlur={validateField}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSeeConfirmPassword(!seeConfirmPassword)}
                >
                  {!seeConfirmPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <ButtonSpinner loading={loading} onClick={singUp}>
              REGISTRAR
            </ButtonSpinner>
          </Grid>
        </Grid>
      </Grid>
      <Toast
        open={openToast}
        message={message}
        close={() => {
          setOpenToast(false);
          if (canRedirect) {
            props.handleClose();
            props.openActivate();
          }
        }}
      />
    </Modal>
  );
}
