import React, { useState, useEffect } from "react";
import {
  Modal,
  Toast,
  // ButtonSpinner
} from ".";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { userServices } from "../services/";
import { useLocation, useHistory } from "react-router-dom";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const useStyles = makeStyles((theme) => ({
  textTitle: {
    color: "rgba(15, 16, 49, 1)",
    fontSize: "16px",
    fontWeight: 700,
  },
  top: {
    marginTop: "32px",
  },
  top2: {
    marginTop: "15px",
  },
}));
export default function VerificationForm(props) {
  const classes = useStyles();
  const [code, setCode] = useState("");
  // const [loading, setLoading] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [canRedirect, setCanRedirect] = useState(false);
  let query = useQuery();
  let history = useHistory();
  useEffect(() => {
    const code = query.get("verificar");
    if (code) {
      setCode(code);
      validateUser(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const validateUser = async (code) => {
    // setLoading(true);
    const { response, status } = await userServices.validateUser(code);
    // setLoading(false);
    setOpenToast(true);
    if (status === 200) {
      setMessage(response.message);
      setCanRedirect(true);
      localStorage.setItem("token", response.token);
      localStorage.setItem(
        "user",
        `${response.user.firstName} ${response.user.lastName}`
      );
    } else {
      setMessage(response.error.message);
    }
  };
  return (
    <Modal {...props}>
      <Grid container className={classes.top} justifyContent="center">
        <Typography className={classes.textTitle} align="center">
          Verificar cuenta
        </Typography>
      </Grid>
      <Grid
        container
        className={classes.top2}
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography style={{ color: "rgba(15, 16, 49, 1)" }}>
            Haz click en el enlace que llego a tu correo electronico para
            validar tu cuenta.
          </Typography>
        </Grid>
        {!code && (
          <Grid item xs={12}>
            <Typography style={{ color: "rgba(15, 16, 49, 1)" }}>
              No recibí ningun{" "}
              <strong
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props.handleClose();
                  props.openResendToken();
                }}
              >
                correo
              </strong>
            </Typography>
          </Grid>
        )}
        {/* <Grid item xs={12}>
          <Grid container justifyContent="center">
            <ButtonSpinner loading={loading} onClick={() => validateUser(code)}>
              VERIFICAR
            </ButtonSpinner>
          </Grid>
        </Grid> */}
      </Grid>
      <Toast
        open={openToast}
        message={message}
        close={() => {
          setOpenToast(false);
          if (canRedirect) {
            props.handleClose();
            history.push(`/perfil`);
          }
        }}
      />
    </Modal>
  );
}
