import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  Box,
  // Grid,
} from "@material-ui/core";
import { BannerLayOut } from "../components";
import { makeStyles } from "@material-ui/core/styles";
import { LoaderContext, AuthContext } from "../contexts";
import { reservationServices } from "../services";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { Button } from "@mui/material";
import ReservationModal from "../components/ReservationModal";

const STATUSES = {
  cancelled: "Cancelada",
  pendding: "Pendiente",
  approved: "Aprobada",
  in_process: "En proceso",
};

export default function Reservations() {
  const classes = useStyles();
  const rows = [1, 2, 3];
  const { loadingActive, loadingDisabled } = useContext(LoaderContext);
  const { signOut } = useContext(AuthContext);
  const [reservations, setReservations] = useState([]);
  const history = useHistory();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (rowId) => {
    setOpenRowId((prevId) => (prevId === rowId ? "" : rowId));
  };

  const getUserReservations = async () => {
    try {
      loadingActive();
      const token = localStorage.getItem("token");
      const { response, status } =
        await reservationServices.getUserReservations(
          token,
          page + 1,
          rowsPerPage
        );
      if (status === 200) {
        setReservations(response.docs);
      } else if (status === 401) {
        signOut();
        history.push("/");
      }
    } catch (error) {
      console.log({ error });
    } finally {
      loadingDisabled();
    }
  };

  useEffect(() => {
    getUserReservations();
  }, [page, rowsPerPage, history, loadingActive, loadingDisabled, signOut]);

  const [openRowId, setOpenRowId] = React.useState("");

  const [cancelReservationLoading, setCancelReservationLoading] =
    useState(false);
  const handleCancelReservation = async (id) => {
    try {
      setCancelReservationLoading(true);
      const res = await reservationServices.cancel(id);
      getUserReservations();
    } catch (error) {
      alert("A ocurrido un error cancelando la reservacion");
    } finally {
      setCancelReservationLoading(false);
    }
  };

  return (
    <BannerLayOut src="./img/banner.jpg">
      <ReservationModal open={history.location.state?.open} />
      <Container>
        <Typography
          variant="h3"
          align="center"
          color="primary"
          className={`${classes.bold} ${classes.font}`}
          paragraph
        >
          MIS RESERVACIONES
        </Typography>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead className={classes.header}>
                <TableRow>
                  <TableCell align="center">Fecha</TableCell>
                  <TableCell align="center">Hora</TableCell>
                  <TableCell align="center">Deporte</TableCell>
                  <TableCell align="center">Campo</TableCell>
                  <TableCell align="center">Acompañantes</TableCell>
                  <TableCell align="flex-start">Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reservations?.map((reservation, index) => (
                  <React.Fragment key={reservation._id}>
                    <TableRow>
                      <TableCell align="center">{reservation.date}</TableCell>
                      <TableCell align="center">
                        {reservation.hourRef.start}
                      </TableCell>
                      <TableCell align="center">
                        {reservation.sportInfo.sport}
                      </TableCell>
                      <TableCell align="center">
                        {reservation.sportInfo.field}
                      </TableCell>

                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          align="center"
                          onClick={() => handleRowClick(reservation._id)}
                        >
                          {openRowId === reservation._id ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell
                        style={{ position: "relative" }}
                        align="flex-start"
                      >
                        {STATUSES[reservation.status]}
                        {reservation.status === "approved" && (
                          <Button
                            disabled={cancelReservationLoading}
                            onClick={() =>
                              handleCancelReservation(reservation._id)
                            }
                            sx={{ ml: "4px", position: "absolute", top: "20%" }}
                            variant="contained"
                          >
                            Cancelar
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                    {openRowId === reservation._id && (
                      <TableRow key={`${reservation._id} ${index}`}>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={63}
                        >
                          <Collapse in={true} timeout="auto" unmountOnExit>
                            <Box>
                              <Table size="small" aria-label="purchases">
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="flex-start">
                                      Nombre
                                    </TableCell>
                                    <TableCell align="flex-start">
                                      Apellido
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                              <Table size="small" aria-label="purchases">
                                <TableBody>
                                  {reservation?.friends?.map((friend) => (
                                    <TableRow key={friend.date}>
                                      <TableCell align="left">
                                        -{friend.firstName}
                                      </TableCell>
                                      <TableCell align="left">
                                        -{friend.lastName}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página: "
          />
        </Paper>
      </Container>
    </BannerLayOut>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {},
  bold: {
    fontWeight: 800,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
    },
  },
  font: {
    fontFamily: ["Roboto Slab", "serif"].join(","),
  },
  header: {
    backgroundColor: "#2b4a3b",
    "& .MuiTableCell-head": {
      color: "#FFF",
      fontWeight: 700,
    },
  },
}));

// const useStyles = makeStyles((theme) => ({
//   textPrimary: {
//     color: "#fff",
//     fontSize: "65px",
//     fontWeight: 300,
//     lineHeight: "70px",
//   },
//   textPrimaryBold: {
//     color: "rgba(85, 4, 3, 1)",
//     fontSize: "65px",
//     fontWeight: 900,
//     lineHeight: "70px",
//   },
// }));

// const reservations = [
//   {
//     id: 5947,
//     day: "2021-11-13",
//     turno_id: 13,
//     reservable_type: "App\\ScheduleClass",
//     reservable_id: 177,
//     user_id: 14001,
//     created_at: "2021-11-08 23:44:45",
//     updated_at: "2021-11-08 23:44:45",
//     token: "I1J7IsOhhfa1ItTVLoU1c5RvWv3RTlz09GMgQri8rIslOJT61U",
//     status_id: 1,
//     reservable: {
//       id: 177,
//       clase_id: 3,
//       trainer_id: 11,
//       turno_id: 13,
//       day_id: 6,
//       created_at: "2019-06-20 00:00:00",
//       updated_at: "2020-02-07 19:19:48",
//       credit_price: 2,
//       active: 1,
//       isKids: 0,
//       clase: {
//         id: 3,
//         name: "Clase Especial",
//         class_type_id: 3,
//         created_at: "2019-06-18 17:02:11",
//         updated_at: "2019-06-17 17:02:16",
//       },
//       trainer: {
//         id: 11,
//         name: "Entrenador 1",
//         avatar: null,
//         created_at: "2020-06-14 13:37:15",
//         updated_at: "2020-06-14 13:37:18",
//       },
//     },
//     turno: {
//       id: 13,
//       start_time: "19:00",
//       end_time: "20:00",
//       sack_price: 1,
//       ring_price: 2,
//       created_at: "2019-06-13 13:42:45",
//       updated_at: "2019-06-13 13:42:45",
//     },
//     status: {
//       id: 1,
//       name: "Pendiente",
//       created_at: "2019-06-13 13:42:45",
//       updated_at: "2019-06-13 13:42:45",
//     },
//     tranier_reservation: null,
//   },
//   {
//     id: 4643,
//     day: "2021-07-15",
//     turno_id: 12,
//     reservable_type: "App\\ScheduleClass",
//     reservable_id: 113,
//     user_id: 14001,
//     created_at: "2021-07-14 19:59:09",
//     updated_at: "2021-07-14 20:00:29",
//     token: "38BHkCtUwrwtzkzVIiYEVD7jyBSKdqFOHwUdTfvT0KrV1Gc5rT",
//     status_id: 3,
//     reservable: {
//       id: 113,
//       clase_id: 1,
//       trainer_id: 1,
//       turno_id: 12,
//       day_id: 4,
//       created_at: "2019-07-30 09:37:15",
//       updated_at: "2021-10-28 20:18:36",
//       credit_price: 2,
//       active: 1,
//       isKids: 0,
//       clase: {
//         id: 1,
//         name: "Boxeo 101",
//         class_type_id: 2,
//         created_at: "2019-06-17 16:59:19",
//         updated_at: "2019-06-17 16:59:23",
//       },
//       trainer: {
//         id: 1,
//         name: "José García",
//         avatar: null,
//         created_at: "2019-06-17 16:57:51",
//         updated_at: "2019-06-17 16:57:56",
//       },
//     },
//     turno: {
//       id: 12,
//       start_time: "18:00",
//       end_time: "19:00",
//       sack_price: 1,
//       ring_price: 2,
//       created_at: "2019-06-13 13:42:45",
//       updated_at: "2019-06-13 13:42:45",
//     },
//     status: {
//       id: 3,
//       name: "Cancelado",
//       created_at: "2019-06-13 13:42:45",
//       updated_at: "2019-06-13 13:42:45",
//     },
//     tranier_reservation: null,
//   },
//   {
//     id: 4362,
//     day: "2021-07-03",
//     turno_id: 13,
//     reservable_type: "App\\ScheduleClass",
//     reservable_id: 177,
//     user_id: 14001,
//     created_at: "2021-06-30 14:11:41",
//     updated_at: "2021-07-03 01:38:43",
//     token: "LWes7u8anSnb0xtiFczaXwDzEonZbX0m4FCNvtQNDknaRTvW36",
//     status_id: 3,
//     reservable: {
//       id: 177,
//       clase_id: 3,
//       trainer_id: 11,
//       turno_id: 13,
//       day_id: 6,
//       created_at: "2019-06-20 00:00:00",
//       updated_at: "2020-02-07 19:19:48",
//       credit_price: 2,
//       active: 1,
//       isKids: 0,
//       clase: {
//         id: 3,
//         name: "Clase Especial",
//         class_type_id: 3,
//         created_at: "2019-06-18 17:02:11",
//         updated_at: "2019-06-17 17:02:16",
//       },
//       trainer: {
//         id: 11,
//         name: "Entrenador 1",
//         avatar: null,
//         created_at: "2020-06-14 13:37:15",
//         updated_at: "2020-06-14 13:37:18",
//       },
//     },
//     turno: {
//       id: 13,
//       start_time: "19:00",
//       end_time: "20:00",
//       sack_price: 1,
//       ring_price: 2,
//       created_at: "2019-06-13 13:42:45",
//       updated_at: "2019-06-13 13:42:45",
//     },
//     status: {
//       id: 3,
//       name: "Cancelado",
//       created_at: "2019-06-13 13:42:45",
//       updated_at: "2019-06-13 13:42:45",
//     },
//     tranier_reservation: null,
//   },
//   {
//     id: 3627,
//     day: "2021-03-25",
//     turno_id: 8,
//     reservable_type: "App\\ScheduleClass",
//     reservable_id: 105,
//     user_id: 14001,
//     created_at: "2021-03-25 14:21:09",
//     updated_at: "2021-03-25 14:26:54",
//     token: "6kYZxycV0QyQ6I1aXxiYwRecVSCP52YBwIeibKQFnj4LfX78yP",
//     status_id: 3,
//     reservable: {
//       id: 105,
//       clase_id: 1,
//       trainer_id: 1,
//       turno_id: 8,
//       day_id: 4,
//       created_at: "2019-07-30 09:37:15",
//       updated_at: "2021-07-15 10:06:15",
//       credit_price: 2,
//       active: 1,
//       isKids: 0,
//       clase: {
//         id: 1,
//         name: "Boxeo 101",
//         class_type_id: 2,
//         created_at: "2019-06-17 16:59:19",
//         updated_at: "2019-06-17 16:59:23",
//       },
//       trainer: {
//         id: 1,
//         name: "José García",
//         avatar: null,
//         created_at: "2019-06-17 16:57:51",
//         updated_at: "2019-06-17 16:57:56",
//       },
//     },
//     turno: {
//       id: 8,
//       start_time: "14:00",
//       end_time: "15:00",
//       sack_price: 1,
//       ring_price: 2,
//       created_at: "2019-06-13 13:42:45",
//       updated_at: "2019-06-13 13:42:45",
//     },
//     status: {
//       id: 3,
//       name: "Cancelado",
//       created_at: "2019-06-13 13:42:45",
//       updated_at: "2019-06-13 13:42:45",
//     },
//     tranier_reservation: null,
//   },
//   {
//     id: 3629,
//     day: "2021-03-25",
//     turno_id: 11,
//     reservable_type: "App\\ScheduleClass",
//     reservable_id: 111,
//     user_id: 14001,
//     created_at: "2021-03-25 15:40:58",
//     updated_at: "2021-03-25 16:49:57",
//     token: "d7AUM9XGFHAh5z1McEX1FyKL0wrPcmnIOf5dllzf2U2t5cEP0I",
//     status_id: 3,
//     reservable: {
//       id: 111,
//       clase_id: 8,
//       trainer_id: 4,
//       turno_id: 11,
//       day_id: 4,
//       created_at: "2019-07-30 09:37:15",
//       updated_at: "2021-07-07 23:33:38",
//       credit_price: 2,
//       active: 1,
//       isKids: 0,
//       clase: {
//         id: 8,
//         name: "Funcional",
//         class_type_id: 2,
//         created_at: "2019-11-01 12:35:03",
//         updated_at: "2019-11-01 12:35:07",
//       },
//       trainer: {
//         id: 4,
//         name: "Desiree Hurtado",
//         avatar: null,
//         created_at: "2019-06-18 11:00:00",
//         updated_at: "2019-06-18 11:00:00",
//       },
//     },
//     turno: {
//       id: 11,
//       start_time: "17:00",
//       end_time: "18:00",
//       sack_price: 1,
//       ring_price: 2,
//       created_at: "2019-06-13 13:42:45",
//       updated_at: "2019-06-13 13:42:45",
//     },
//     status: {
//       id: 3,
//       name: "Cancelado",
//       created_at: "2019-06-13 13:42:45",
//       updated_at: "2019-06-13 13:42:45",
//     },
//     tranier_reservation: null,
//   },
// ];
