import React from "react";
import { TextField } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  borderRadius: {
    borderRadius: "15px",
  },
  padding: {
    padding: "10px 10px",
  },
}));

export default function MyTextField(props) {
  const classes = useStyles();
  return (
    <TextField
      {...props}
      InputProps={{
        className: classes.borderRadius,
        ...props.InputProps
      }}
      inputProps={{
        className: classes.padding,
        ...props.inputProps
      }}
      
    />
  );
}
