import React, { useState, useEffect } from "react";
import { Modal, TextField, ButtonSpinner, Toast } from ".";
import { Grid, Typography, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { FormValidator } from "../helpers";
import { userServices } from "../services/";
import { useLocation } from "react-router-dom";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const useStyles = makeStyles((theme) => ({
  textTitle: {
    color: "rgba(15, 16, 49, 1)",
    fontSize: "16px",
    fontWeight: 700,
  },
  top: {
    marginTop: "32px",
  },
}));
export default function ResetPasswordForm(props) {
  const classes = useStyles();
  const [code, setCode] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [canRedirect, setCanRedirect] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [seePassword, setSeePassword] = useState(false);
  const [seeConfirmPassword, setSeeConfirmPassword] = useState(false);
  let query = useQuery();
  useEffect(() => {
    const code = query.get("code");
    if (code) {
      setCode(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const passwordMatch = (confirmation) => password === confirmation;
  const passwordValidate = (password) => {
    const regex = new RegExp(
      "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
    );
    if (regex.test(password)) {
      return true;
    }
    return false;
  };
  const validator = new FormValidator([
    {
      field: "code",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese el código",
    },
    {
      field: "password",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese una contraseña",
    },
    {
      field: "password",
      method: passwordValidate,
      validWhen: true,
      message: "La contraseña debe tener mínimo 5 caracteres y un número",
    },
    {
      field: "confirmPassword",
      method: "isEmpty",
      validWhen: false,
      message: "Confirme su contraseña",
    },
    {
      field: "confirmPassword",
      method: passwordMatch,
      validWhen: true,
      message: "Las contraseñas no coinciden",
    },
  ]);
  const resetPassword = async () => {
    const validation = validator.validate({
      code,
      password,
      confirmPassword,
    });
    setErrors(validation);
    if (validation.isValid) {
      setLoading(true);
      const { response, status } = await userServices.resetPassword({
        token: code,
        password,
      });
      setLoading(false);
      setOpenToast(true);
      if (status === 200) {
        setCanRedirect(true);
        setMessage(response.message);
      } else {
        setMessage(response.error.message);
      }
    }
  };
  return (
    <Modal {...props}>
      <Grid container className={classes.top} justifyContent="center">
        <Typography className={classes.textTitle} align="center">
          Ingresa el codigo que hemos enviado a tu correo y su nueva contraseña
        </Typography>
      </Grid>
      <Grid
        container
        className={classes.top}
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <TextField
            placeholder="Código"
            variant="outlined"
            fullWidth
            value={code}
            onChange={(e) => setCode(e.target.value)}
            error={errors.code ? errors.code.isInvalid : false}
            helperText={errors.code ? errors.code.message : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="Contraseña"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={seePassword ? "text" : "password"}
            error={errors.password ? errors.password.isInvalid : false}
            helperText={errors.password ? errors.password.message : ""}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSeePassword(!seePassword)}
                >
                  {!seePassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="Confirmar contraseña"
            variant="outlined"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type={seeConfirmPassword ? "text" : "password"}
            error={
              errors.confirmPassword ? errors.confirmPassword.isInvalid : false
            }
            helperText={
              errors.confirmPassword ? errors.confirmPassword.message : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSeeConfirmPassword(!seeConfirmPassword)}
                >
                  {!seeConfirmPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <ButtonSpinner loading={loading} onClick={resetPassword}>
              REGISTRAR
            </ButtonSpinner>
          </Grid>
        </Grid>
      </Grid>
      <Toast
        open={openToast}
        message={message}
        close={() => {
          setOpenToast(false);
          if (canRedirect) {
            props.handleClose();
            props.openLogin();
          }
        }}
      />
    </Modal>
  );
}
