import React, { useState } from "react";
import { Modal, TextField, ButtonSpinner, Toast } from ".";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormValidator } from "../helpers";
import { userServices } from "../services/";
const useStyles = makeStyles((theme) => ({
  textTitle: {
    color: "rgba(15, 16, 49, 1)",
    fontSize: "16px",
    fontWeight: 700,
  },
  top: {
    marginTop: "32px",
  },
}));
export default function ResetPasswordRequestForm(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [canRedirect, setCanRedirect] = useState(false);
  const validator = new FormValidator([
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese su correo electrónico",
    },
    {
      field: "email",
      method: "isEmail",
      validWhen: true,
      message: "Ingrese un correo electrónico valido",
    },
  ]);
  const resetPasswordRequest = async () => {
    const validation = validator.validate({
      email,
    });
    setErrors(validation);
    if (validation.isValid) {
      setLoading(true);
      const { response, status } = await userServices.resetPasswordRequest({
        email,
      });
      setLoading(false);
      setOpenToast(true);
      if (status === 200) {
        setMessage(response.message);
        setCanRedirect(true);
      } else {
        setMessage(response.error.message);
      }
    }
  };
  return (
    <Modal {...props}>
      <Grid container className={classes.top} justifyContent="center">
        <Typography className={classes.textTitle} align="center">
          Ingresa tu correo para recuperar tu contraseña
        </Typography>
      </Grid>
      <Grid
        container
        className={classes.top}
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <TextField
            placeholder="Correo electrónico"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errors.email ? errors.email.isInvalid : false}
            helperText={errors.email ? errors.email.message : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <ButtonSpinner loading={loading} onClick={resetPasswordRequest}>
              ENVIAR
            </ButtonSpinner>
          </Grid>
        </Grid>
      </Grid>
      <Toast
        open={openToast}
        message={message}
        close={() => {
          setOpenToast(false);
          if (canRedirect) {
            props.handleClose();
            props.openResetPassword();
          }
        }}
      />
    </Modal>
  );
}
