import urls from "../urls";

const getDollarPrice = async () => {
  const request = await fetch(`${urls.dollar}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};
const updateDollarPrice = async (price, token) => {
  const request = await fetch(`${urls.dollar}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ price }),
  });
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};

const dollarServices = Object.freeze({
  getDollarPrice,
  updateDollarPrice,
});

export default dollarServices;
