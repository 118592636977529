import urls from "../urls";

const getSchedule = async (body) => {
  const request = await fetch(`${urls.schedule}/schedule`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};

const scheduleServices = Object.freeze({
  getSchedule,
});

export default scheduleServices;
