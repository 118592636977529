import React from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      //light: "rgba(103, 58, 183, 1)",
      main: "#2b4a3b",
      //dark: "rgba(81, 45, 168, 1)",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    secondary: {
      //light: "#000",
      main: "rgba(12, 87, 4, 1)",
      //dark: "#000",
      contrastText: "rgba(255, 255, 255, 1)",
    },
  },
});

export default function AppTheme({ children }) {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
