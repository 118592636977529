import React from "react";
import { AppBar, Copyright, Footer } from "./"
export default function LayOut(props){
    return (
        <div>
            <AppBar/>
            { props.children }
            <Footer/>
            <Copyright {...props}/>
        </div>
    )
}