import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Grid } from "@mui/material";

export default function Avatar() {
  const classes = useStyles();
  const user = localStorage.getItem("user");
  const isSolvent = localStorage.getItem("isSolvent");
  const handicap = localStorage.getItem("handicap");
  function getSolventStatus(isSolvent) {
    return isSolvent === "true" ? "Solvente" : "Insolvente";
  }

  function getAvatar(userName) {
    const user = userName.split(" ");
    return `${user[0].charAt(0).toUpperCase()}${user[1]
      .charAt(0)
      .toUpperCase()}`;
  }

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const capitalizeWords = (str) => {
    const words = str.split(" ");
    const capitalizedWords = words.map((word) => capitalizeFirstLetter(word));
    return capitalizedWords.join(" ");
  };

  const statusColor = isSolvent === "true" ? "#FFBE00" : "#FF0000";

  return (
    <div className={classes.root}>
      <div className={classes.avatar} style={{ backgroundColor: statusColor }}>
        <Typography variant="h4">{getAvatar(user)}</Typography>
      </div>
      <div>
        <Typography variant="h5" style={{ fontSize: "1.3rem" }}>
          Bienvenido
        </Typography>
        <Typography variant="h5" style={{ fontSize: "1.3rem" }}>
          {capitalizeWords(user)}
        </Typography>
        <Grid style={{ display: "flex" }}>
          <Typography
            variant="h5"
            style={{ fontSize: "1.3rem", display: "flex" }}
          >
            Estatus:&nbsp;
            <Typography
              variant="h5"
              style={{ fontSize: "1.3rem", color: statusColor }}
            >
              {getSolventStatus(isSolvent)}
            </Typography>
          </Typography>
        </Grid>

        <Typography variant="h5" style={{ fontSize: "1.3rem" }}>
          Handicap: {handicap}
        </Typography>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    gap: 40,
    padding: 20,
    color: "#FFF",
    alignItems: "center",
  },
  avatar: {
    display: "flex",
    borderRadius: "50%",
    padding: 20,
  },
}));
