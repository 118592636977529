import React, { useState, useEffect } from "react";
import { Select } from ".";
import { MenuItem } from "@material-ui/core/"
import { makeStyles } from "@material-ui/core/styles";
import { sportServices } from "../services";
const useStyles = makeStyles(theme => ({
  select:{
      background:"#FFF"
  }
}));
export default function SportsSelect(props){
    const classes = useStyles();
    const [sports, setSports] = useState([])

    const getSports = async ()=>{
        const { response, status } = await sportServices.getSport()
        if(status===200){
            setSports(response)
        }
    }

    useEffect(()=>{
        getSports()
    }, [])
    return (
        <Select {...props} classes={{ root: classes.select }}>
            {sports.map(sport=>(
               <MenuItem key={sport._id} value={sport._id}>{sport.name}</MenuItem> 
            ))}
        </Select>
    )
}