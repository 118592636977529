import React, { useReducer, useMemo } from "react";
import Routes from "./routes";
import { AuthContext, ModalContext, LoaderContext } from "./contexts";
import "./App.css";
import { Loader } from "./components";

function App() {
  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            token: action.token,
            isLoading: false,
            user: action.user,
          };
        case "LOGIN":
          return {
            ...prevState,
            isSignout: false,
            token: action.token,
            user: action.user,
          };
        case "LOGOUT":
          return {
            ...prevState,
            isSignout: true,
            token: null,
            user: null,
          };
        default: {
        }
      }
    },
    {
      isLoading: true,
      isSignout: false,
      token: localStorage.getItem("token"),
      //user: JSON.parse(localStorage.getItem("user")),
    }
  );
  const [stateModal, dispatchModal] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "OPEN":
          return {
            ...prevState,
            open: true,
          };
        case "CLOSE":
          return {
            ...prevState,
            open: false,
          };
        default: {
          return {
            ...prevState,
          };
        }
      }
    },
    {
      open: false,
    }
  );
  const modalContext = useMemo(() => {
    return {
      stateModal,
      open: () => {
        dispatchModal({ type: "OPEN" });
      },
      close: () => {
        dispatchModal({ type: "CLOSE" });
      },
    };
  }, [stateModal]);
  const authContext = useMemo(() => {
    return {
      state,
      signIn: ({ token, user }) => {
        try {
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(user));
        } catch (e) {
          console.log(e);
        }
        dispatch({ type: "LOGIN", token, user });
      },
      signOut: () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("avatar");
        localStorage.removeItem("membership");
        localStorage.removeItem("key");
        localStorage.removeItem("id");
        dispatch({ type: "LOGOUT" });
      },
    };
  }, [state]);

  const [loaderState, loaderDispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "LOAD":
          return {
            loadingQueue: prevState.loadingQueue + 1,
            isLoading: true,
          };
        case "LOAD_READY":
          if (prevState.loadingQueue <= 1) {
            return {
              loadingQueue: 0,
              isLoading: false,
            };
          }
          return {
            loadingQueue: prevState.loadingQueue - 1,
            isLoading: true,
          };
        default:
          break;
      }
    },
    {
      loadingQueue: 0,
      isLoading: false,
    }
  );

  const loaderContext = useMemo(
    () => ({
      loadingActive: async () => {
        loaderDispatch({ type: "LOAD" });
      },
      loadingDisabled: async () => {
        loaderDispatch({ type: "LOAD_READY" });
      },
    }),
    []
  );

  return (
    <div className="mainWrapper">
      <AuthContext.Provider value={authContext}>
        <ModalContext.Provider value={modalContext}>
          <LoaderContext.Provider value={loaderContext}>
            <Loader open={loaderState.isLoading} />
            <Routes />
          </LoaderContext.Provider>
        </ModalContext.Provider>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
