import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  buttonProgress: {
    color: "#DA1F32",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ButtonSpinner(props) {
  const classes = useStyles();
  return (
    <Button
      variant={props.variant ? props.variant : "contained"}
      color={props.color ? props.color : "primary"}
      fullWidth={props.fullWidth}
      className={props.className}
      classes={props.classes}
      disabled={props.loading || props.disabled}
      onClick={props.onClick}
      style={{
        borderRadius: "100px",
        ...props.style,
      }}
      disableElevation={props.disableElevation}
      type={props.type || "text"}
    >
      {props.loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}

      {props.children}
    </Button>
  );
}
