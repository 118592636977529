import React from "react";
import { Modal } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loader = ({ open }) => {
  return (
    <Modal
      open={open}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress style={{ outline: "none" }} />
    </Modal>
  );
};

export default Loader;
