import React from "react";
import { Grid } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = ({ src, filter }) =>
  makeStyles((theme) => ({
    banner: {
      // minHeight: "100vh",
      backgroundImage: `url(${src})`,
      backgroundPosition: "bottom",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      overflowX: "hidden",
    },
    header: {
      width: "100%",
      //position: "absolute",
      top: 0,
    },
    filter: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: "absolute",
      background: `${filter}`,
      zIndex: 100,
    },
  }));

export default function BannerLayOut(props) {
  const classes = useStyles({ ...props })();
  return (
    <Grid container className={classes.banner} justifyContent="center">
      <div className={classes.header}></div>
      {props.filter && <Grid className={classes.filter}></Grid>}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "85vh", paddingTop: 60, paddingBottom: 60 }}
      >
        {props.children}
      </Grid>
    </Grid>
  );
}
