import urls from "../urls";

const payOrder = async (body, token) => {
  const request = await fetch(`${urls.order}/payment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};

const orderServices = Object.freeze({
  payOrder,
});

export default orderServices;
