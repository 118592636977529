import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { BannerLayOut, ButtonSpinner } from "../components";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: "#2b4a3b",
    fontSize: "65px",
    fontWeight: 300,
    lineHeight: "70px",
  },
  textPrimaryBold2: {
    color: "#2b4a3b",
    fontSize: "65px",
    fontWeight: 600,
    lineHeight: "70px",
  }
  ,
  textPrimaryBold: {
    color: "#2b4a3b",
    fontSize: "65px",
    fontWeight: 400,
    lineHeight: "70px",
  },
  [theme.breakpoints.down("sm")]: {
    textPrimary: {
      fontSize: "3rem",
    },
    textPrimaryBold: {
      fontSize: "3rem",
    },
  },
}));
export default function Landing() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ paddingBottom: "10px" }}
      >
        <BannerLayOut src="./img/banner.jpg">
          <Grid container justifyContent="center">
            <Grid item xs={12} md={6}>
              <Grid container justifyContent="center">
                <Grid item xs={11} md={7}>
                  <Typography className={classes.textPrimary}>
                    La Salina
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={11} md={7}>
                  <Typography className={classes.textPrimaryBold}>
                    Golf Club
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ margin: matches ? "20px 0" : 0 }}
            >
              <Grid container justifyContent="center">
                <Grid item xs={11} md={7}>
                  <Typography
                    sx={{ color: '#2b4a3b' }}
                    align={matches ? "left" : "right"}
                    className={classes.textPrimaryBold2}
                  >
                    Reserva
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={11} md={7}>
                  <Typography
                    align={matches ? "left" : "right"}
                    className={classes.textPrimaryBold2}
                  >
                    Tu Tee Time
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={9}>
              <ButtonSpinner
                style={{
                  backgroundColor:'#2b4a3b',
                  color:'#f9e189',
                  fontSize: "34px",
                  fontWeight: 900,
                  lineHeight: "42px",
                  paddingRight: "40px",
                  paddingLeft: "40px",
                }}
                onClick={() => history.push("/reservar")}
              >
                RESERVAR
              </ButtonSpinner>
            </Grid>
          </Grid>
        </BannerLayOut>
      </Grid>
    </Grid>
  );
}
