import urls from "../urls";

const register = async (body) => {
  const request = await fetch(urls.auth.register, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};

const login = async (body) => {
  const request = await fetch(urls.auth.login, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
}

const resetPasswordRequest = async (body) => {
  const request = await fetch(urls.paswordReset.paswordResetRequest, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
}
const resetPassword = async (body) => {
  const request = await fetch(urls.paswordReset.paswordReset, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
}


const validateUser = async (token) => {
  const request = await fetch(`${urls.auth.active}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token })
  });
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
}

const getUsers = async (page, limit, filtered) => {
  const token = localStorage.getItem("token");
  const request = await fetch(
    `${urls.getUsers}?limit=${limit}&page=${page}&filtered=${filtered}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};
const findUser = async (page, limit, query) => {
  const token = localStorage.getItem("token");
  const request = await fetch(
    `${urls.findUser}?limit=${limit}&page=${page}&query=${query}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};

const resendEmail = async (body) => {
  const request = await fetch(urls.auth.sendToken, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body)
  });
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
}
const userServices = Object.freeze({
  register,
  login,
  validateUser,
  getUsers,
  findUser,
  resetPassword,
  resetPasswordRequest,
  resendEmail,
});

export default userServices;
