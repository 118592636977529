import React from "react";
import { MuiPickersUtilsProvider, DatePicker as MaterialDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { makeStyles } from "@material-ui/core/styles";
import "moment/locale/es";
const useStyles = makeStyles(theme => ({
    formControl: {
      width: "100%",
      background:"#fff"
    }
}));

export default function DatePicker(props){
    const classes = useStyles();

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
            <MaterialDatePicker
                inputVariant={props.inputVariant? props.inputVariant : "outlined"}
                variant={props.variant ? props.variant : "inline"}
                disablePast={props.disablePast}
                disableFuture={props.disableFuture}
                disabled={props.disabled}
                minDate={props.minDate}
                label={props.label}
                error={props.error}
                autoOk={props.autoOk? props.autoOk: true}
                disableToolbar={!props.toolbar}
                className={classes.formControl}
                format={props.format?props.format:"DD-MM-yyyy"}
                maxDate={props.maxDate}
                allowKeyboardControl={false}
                value={props.value}
                onChange={props.onChange ? props.onChange : () => {}}
            />
        </MuiPickersUtilsProvider>
    )
}