import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  Fragment,
} from "react";
import { BannerLayOut } from "../components";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { addMonths, format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
// import Slider from "react-slick";
import {
  makeStyles,
  Container,
  Typography,
  TextField,
  MenuItem,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Grid,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  scheduleServices,
  sportServices,
  // hourServices,
  reservationServices,
} from "../services";
import { LoaderContext, ModalContext } from "../contexts";

import Modal from "./Modal";
import styled from "styled-components";
import TransferList from "./lister";

const PopUp = ({ onClick }) => {
  const [estadoModal1, cambiarEstadoModal1] = useState(false);

  return (
    <div>
      <ContenedorBotones>
        <Boton
          style={{
            backgroundColor: "#2b4a3b",
            width: "140px",
            height: "50px",
            fontSize: "20px",
          }}
          onClick={() => cambiarEstadoModal1(!estadoModal1)}
        >
          Reservar
        </Boton>
      </ContenedorBotones>

      {/* Modal #1 */}
      <Modal
        estado={estadoModal1}
        cambiarEstado={cambiarEstadoModal1}
        titulo="COMPLETAR RESERVACION"
        mostrarHeader={true}
        mostrarOverlay={true}
        posicionModal={"center"}
      >
        <Contenido>
          <TransferList onReservation={onClick} />
        </Contenido>
      </Modal>
    </div>
  );
};

const ContenedorBotones = styled.div`
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
  "@media (max-width: 400px)": {
    padding: "1px";
  }
`;

const Boton = styled.button`
  display: block;
  padding: 10px 30px;
  border-radius: 100px;
  color: #fff;
  border: none;
  background: #1766dc;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  transition: 0.3s ease all;
  &:hover {
    background: #0066ff;
  }
  "@media (max-width: 400px)": {
    padding: 1px 1px;
  }
`;

const Contenido = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: -10px;
    color: #2b4a3b;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  img {
    width: 100%;
    vertical-align: top;
    border-radius: 3px;
  }
`;
// function Arrow({ className, style, onClick, direction }) {
//   const POSITIONS = {
//     right: {
//       left: 0,
//     },
//     left: {
//       right: 0,
//     },
//   };
//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//         height: 36,
//         position: "absolute",
//         top: "50%",
//         transform: `rotate(${
//           direction === "right" ? 180 : 0
//         }deg) translate(0, ${direction === "right" ? "50%" : "-50%"})`,
//         zIndex: 1,
//         ...POSITIONS[direction],
//       }}
//       onClick={onClick}
//     >
//       <img src="/img/arrow.svg" alt="Flecha" />
//     </div>
//   );
// }

export default function MakeReservation(props) {
  const classes = useStyles();
  const [sports, setSports] = useState([]);
  const [sport, setSport] = useState("");
  const [selectedDate, handleDateChange] = useState(new Date());
  // const [field, setField] = useState(-1);
  // const [reservedHours, setReservedHours] = useState([]);
  const [schedule, setSchedule] = useState([]); // all schedules
  const [selectedSchedule, setSelectedSchedule] = useState([]);
  // const [hours, setHours] = useState([]);
  const [total, setTotal] = useState(0);
  const { open } = useContext(ModalContext);
  // const [fieldIndex, setFieldIndex] = useState(-1);

  // const settings = {
  //   slidesToShow: 4,
  //   slidesToScroll: 4,
  //   prevArrow: <Arrow direction="right" />,
  //   nextArrow: <Arrow direction="left" />,
  // };

  function convertTo12Hour(oldFormatTime) {
    var oldFormatTimeArray = oldFormatTime.split(":");

    var HH = parseInt(oldFormatTimeArray[0]);
    var min = oldFormatTimeArray[1];

    var AMPM = HH >= 12 ? "PM" : "AM";
    var hours;
    if (HH === 0) {
      hours = HH + 12;
    } else if (HH > 12) {
      hours = HH - 12;
    } else {
      hours = HH;
    }
    return hours + ":" + min + " " + AMPM;
  }

  const { loadingActive, loadingDisabled } = useContext(LoaderContext);

  const history = useHistory();

  useEffect(() => {
    const newTotal = selectedSchedule.reduce(
      (acc, current) => current.schedule.price + acc,
      0
    );
    setTotal(newTotal);
  }, [selectedSchedule]);

  const getSchedule = useCallback(
    async (selectedSport) => {
      try {
        loadingActive();
        const { response, status } = await scheduleServices.getSchedule({
          sportId: selectedSport._id,
          day: format(selectedDate, "yyyy-MM-dd"),
        });
        if (status === 200) {
          console.log(response[0]?.hourRef);
          setSchedule(response);
        }
      } catch (error) {
        console.log({ error });
      } finally {
        loadingDisabled();
      }
    },
    [loadingActive, loadingDisabled, selectedDate]
  );

  useEffect(() => {
    setSelectedSchedule([]);
    if (Object.keys(sport || {}).length) {
      getSchedule(sport);
    }
  }, [selectedDate, getSchedule, sport]);

  useEffect(() => {
    const getSports = async () => {
      try {
        loadingActive();
        const { response, status } = await sportServices.getSport();
        if (status === 200) {
          setSports(response);
          setSport(response[0]);
        }
      } catch (error) {
        console.log({ error });
      } finally {
        loadingDisabled();
      }
    };
    // const getHours = async () => {
    //   try {
    //     loadingActive();
    //     const { response, status } = await hourServices.getHours();
    //     if (status === 200) {
    //       setHours(response);
    //     }
    //   } catch (error) {
    //     console.log({ error });
    //   } finally {
    //     loadingDisabled();
    //   }
    // };
    getSports();
    // getHours();
  }, [loadingActive, loadingDisabled]);

  const handleSelect = (e) => {
    const selectedSportId = e.target.value;
    const index = sports.findIndex(({ _id }) => selectedSportId === _id);
    setSport(sports[index]);
    // setField(-1);
    setSelectedSchedule([]);
    getSchedule(sports[index]);
  };

  const bookSchedule = (selected, scheduleIndex) => {
    if (!checkTime(selected.schedule[scheduleIndex].hourRef.start)) {
      return;
    }

    const selectedScheduleId = selected.schedule[scheduleIndex].scheduleId;
    const alreadySelected = selectedSchedule.some(
      (schedule) => schedule.schedule.scheduleId === selectedScheduleId
    );

    if (alreadySelected) {
      setSelectedSchedule([]);
    } else {
      setSelectedSchedule([
        { field: selected.field, schedule: selected.schedule[scheduleIndex] },
      ]);
    }
  };

  const checkReservation = (_id) => {
    return (
      selectedSchedule.findIndex(
        (schedule) => schedule.schedule.scheduleId === _id
      ) > -1
    );
  };

  const checkTime = (startHour) => {
    return (
      selectedDate.getDate() > new Date().getDate() ||
      parseInt(startHour) > new Date().getHours()
    );
  };

  const reserve = async ({ users }) => {
    if (!selectedSchedule.length) {
      return alert("Seleccione una hora primero");
    }

    const token = localStorage.getItem("token");
    if (!token) {
      open();
    }

    try {
      loadingActive();
      const formattedDate = format(selectedDate, "yyyy-MM-dd");
      const reservation = selectedSchedule.map(({ schedule }) => ({
        scheduleId: schedule.scheduleId,
        date: formattedDate,
        price: schedule.price,
      }));
      const { response, status } = await reservationServices.addReservation({
        schedules: reservation,
        token,
        users,
      });
      if (status === 200) {
        history.push("/mis-reservas", {
          total,
          bookingId: response.bookingId,
          open: true,
        });
      }
    } catch (error) {
      console.log({ error });
    } finally {
      loadingDisabled();
    }
  };

  // const handleSelectField = (fieldIndex) => {
  //   // setFieldIndex(index);
  //   setField(fieldIndex);
  //   setSelectedSchedule([]);
  // };

  const isFieldSelected = (field) => {
    return selectedSchedule?.find((schedule) => schedule.field === field);
  };

  return (
    <BannerLayOut src="./img/banner2.jpg">
      <Container>
        <Typography
          variant="h2"
          className={`${classes.title} ${classes.font}`}
          color="primary"
          gutterBottom
        >
          RESERVAR
        </Typography>
        <Grid container spacing={8} style={{ marginBottom: 20 }}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              value={sport?._id || ""}
              onChange={handleSelect}
              select
              fullWidth
              className={classes.field}
              SelectProps={{ displayEmpty: true }}
              inputProps={{ className: `${classes.sport} ${classes.font}` }}
            >
              <MenuItem value="" disabled>
                Deporte
              </MenuItem>
              {sports.map(({ name, _id }) => (
                <MenuItem value={_id} key={name}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                disablePast
                fullWidth
                inputVariant="outlined"
                value={selectedDate}
                onChange={handleDateChange}
                className={classes.field}
                format="dd MMMM yyyy"
                maxDate={addMonths(new Date(), 1)}
                autoOk={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Paper>
          <TableContainer>
            <Table
              style={{
                userSelect: "none",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">Hora</TableCell>
                  {schedule.map((availability) => (
                    <TableCell align="center" key={availability.field}>
                      Campo {availability.field}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {schedule[0]?.schedule?.map(({ hourRef }, index) => (
                  <TableRow key={index}>
                    <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                      {convertTo12Hour(hourRef.start)}
                    </TableCell>
                    {schedule.map((availability) => {
                      const isAvailable =
                        checkTime(
                          availability.schedule[index]?.hourRef.start
                        ) && availability.schedule[index]?.isAvailable;
                      const isReserved = checkReservation(
                        availability.schedule[index]?.scheduleId
                      );
                      return (
                        <TableCell
                          key={availability.field}
                          {...(isAvailable
                            ? {
                                onClick: () =>
                                  bookSchedule(availability, index),
                              }
                            : {})}
                          align="center"
                          style={{
                            whiteSpace: "nowrap",
                            backgroundColor: isReserved
                              ? "#FFBE00"
                              : availability.schedule[index]?.isAvailable ===
                                false
                              ? "#55030280"
                              : isAvailable
                              ? "#0C5704"
                              : "#2b4a3b",
                            color: "white",
                            fontWeight: 700,
                            cursor: isAvailable ? "pointer" : "default",
                          }}
                        >
                          {/* {isAvailable
                            ? `${availability.schedule[index]?.price} $`
                            : availability.schedule[index]?.isAvailable ===
                              false
                            ? "RESERVADO"
                            : "NO DISPONIBLE"} */}
                          {isAvailable
                            ? "PRESIONE PARA RESERVAR"
                            : availability.schedule[index]?.isSpecial ===
                                true &&
                              availability.schedule[index]?.isAvailable ===
                                false
                            ? "DESHABILITADO"
                            : availability.schedule[index]?.isAvailable ===
                              false
                            ? "RESERVADO"
                            : "NO DISPONIBLE"}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Typography
          style={{ textAlignLast: "center", color: "rgb(249, 225, 137)" }}
          variant="h2"
          className={`${classes.title} ${classes.font}`}
          gutterBottom
        >
          RESUMEN
        </Typography>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Campo</TableCell>
                  <TableCell align="center">Hora</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedSchedule.map((availability) => (
                  <TableRow key={availability.schedule.scheduleId}>
                    <TableCell align="center">{availability.field}</TableCell>
                    <TableCell align="center">
                      {availability.schedule.hourRef.start}
                    </TableCell>
                  </TableRow>
                ))}
                {/* {selectedSchedule.length > 0 && (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="center">Total</TableCell>
                    <TableCell align="center">
                      {selectedSchedule.reduce(
                        (acc, current) => current.schedule.price + acc,
                        0
                      )}{" "}
                      $
                    </TableCell>
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <div className={classes.reservationContainer}>
          <PopUp onClick={reserve} />
        </div>
      </Container>
    </BannerLayOut>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: "red",
  },
  title: {
    fontWeight: 900,
    margin: "20px 0",
  },
  sport: {
    fontSize: "3rem",
    lineHeight: 1,
    padding: "10px 20px",
  },
  field: {
    boxShadow: "0px 4px 4px 0px #00000040",
    marginBottom: 20,
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
    },
  },
  font: {
    fontFamily: ["Roboto Slab", "serif"].join(","),
  },
  availabilityContainer: {
    borderRadius: 15,
    backgroundColor: "#fff",
    padding: 10,
  },
  availability: {
    borderRadius: "inherit",
    backgroundColor: "lightgray",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    gap: 14,
    "& > div": {
      borderRadius: 100,
      display: "flex",
      justifyContent: "space-between",
      gap: 10,
      "& > div": {
        borderRadius: "inherit",
        backgroundColor: "#fff",
        maxWidth: 150,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& > button": {
        borderRadius: "inherit",
        lineHeight: 1,
        maxWidth: 150,
        "&.Mui-disabled": {
          backgroundColor: theme.palette.primary.main,
          color: "#fff",
        },
      },
    },
  },
  reservationContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "30px 0",
    "& > button": {
      fontSize: "1.4rem",
      borderRadius: 50,
    },
  },
  slider: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    "& .slick-arrow": {
      "&:before": {
        display: "none",
      },
    },
    "& .slick-list": {
      padding: "40px 0",
    },
  },
  fieldsContainer: {
    display: "flex",
    flexWrap: "wrap",
    // flexDirection: "column",
    gap: "10px 60px",
    alignItems: "center",
    justifyContent: "center",
    // maxHeight: 400,
    margin: "auto",
  },
  verticalField: {
    width: 100,
  },
  horizontalField: {
    width: 300,
  },
  [theme.breakpoints.down("md")]: {
    fieldsContainer: {
      // maxHeight: 300,
      // gap: 20,
    },
    verticalField: {
      width: 80,
    },
  },
  [theme.breakpoints.down("sm")]: {
    fieldsContainer: {
      // maxHeight: 400,
    },
    verticalField: {
      width: 120,
    },
  },
  [theme.breakpoints.down("xs")]: {
    fieldsContainer: {
      // maxHeight: 200,
      gap: "10px 20px",
    },
    verticalField: {
      width: 60,
    },
  },
}));
