import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cardWhite: {
    background: "#FFF",
    borderLeft: "10px solid #FFBE00",
    padding: "15px",
  },
  tittle: {
    color: "rgba(78, 77, 77, 1)",
    fontSize: "1.2rem",
  },
  cell: {
    background: "#EAEAEA",
    padding: "5px",
    marginTop: "6px",
  },
  cellText: {
    color: "rgba(122, 122, 122, 1)",
  },
}));
const reservations = [
  {
    date: "20-11-2021",
    place: "1",
    hour: "4 pm",
  },
  {
    date: "20-11-2021",
    place: "1",
    hour: "4 pm",
  },
  {
    date: "20-11-2021",
    place: "1",
    hour: "4 pm",
  },
  {
    date: "20-11-2021",
    place: "1",
    hour: "4 pm",
  },
  {
    date: "20-11-2021",
    place: "1",
    hour: "4 pm",
  },
];
export default function ReservationsCard(props) {
  const classes = useStyles();
  const Cell = (props) => {
    return (
      <Grid container className={classes.cell}>
        <Typography className={classes.cellText}>
          {props.date} - Cancha {props.place} - {props.hour}
        </Typography>
      </Grid>
    );
  };
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={12} className={classes.cardWhite}>
        <Typography className={classes.tittle}>HISTORIAL</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.cardWhite}
        style={{ marginTop: "25px", minHeight: "270px" }}
      >
        {reservations.map((reservation, index) => (
          <Cell {...reservation} key={index} />
        ))}
      </Grid>
    </Grid>
  );
}
