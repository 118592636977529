import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    fontWeight: 400,
    color: "#4E4D4D",
  },
  textPrimaryBold: {
    fontWeight: 900,
    color: "rgba(78, 77, 77, 1)",
    marginBottom: "10px",
  },
  box: {
    padding: "40px 0",
  },
  icon: {
    padding: "0 10px",
  },
  [theme.breakpoints.down("sm")]: {
    textPrimaryBold: {
      textAlign: "center",
    },
    textPrimary: {
      textAlign: "center",
    },
  },
}));
export default function Footer(props) {
  const classes = useStyles();
  return (
    <Grid container justifyContent="space-between" className={classes.box}>
      <Grid item md={4} xs={12}>
        <Grid container justifyContent="center">
          <Grid
            item
            md={8}
            xs={10}
            style={{ display: "flex", flexDirection: "column" }}
          ></Grid>
        </Grid>
      </Grid>
      <Grid item md={4} xs={12}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography align="center" className={classes.textPrimaryBold}>
              Nuestras redes
            </Typography>
          </Grid>
          <a
            href="https://www.instagram.com/lasalinagolfclub/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={classes.icon} alt="" src="./img/ig-ico.svg" />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=584248665988"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={classes.icon} alt="" src="./img/ws-ico.svg" />
          </a>
        </Grid>
      </Grid>
      <Grid item md={4} xs={12}>
        <Grid container justifyContent="center">
          <Grid item md={8} xs={10}>
            <Typography align="right" className={classes.textPrimaryBold}>
              Ubicación
            </Typography>
            <Typography align="right" className={classes.textPrimary}>
              El Morro, Lechería, Anzoátegui
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
