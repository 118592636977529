import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableFooter,
  Grid,
} from "@material-ui/core";
import { ButtonSpinner, Spinner, Toast, Modal } from ".";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { reservationServices } from "../services";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const StyledTableCell = withStyles((theme) => ({
  head: {
    background: "#2b4a3b",
    color: "#fff",
    fontWeight: 700,
    fontSize: "13px",
    padding: "7px",
    "&:first-child": {
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
    },
    "&:last-child": {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
    },
  },
  body: {
    color: "rgba(95, 95, 95, 1)",
    fontWeight: 600,
    fontSize: "13px",
    padding: "10px",
    borderBottom: "1px solid rgba(255, 190, 0, 1)",
  },
}))(TableCell);
const StyledTableCell2 = withStyles((theme) => ({
  body: {
    padding: "15px",
    borderBottom: "none",
  },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
  tableBody: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 16px rgb(198 198 198 / 50%)",
    borderRadius: "15px",
  },
  tableContainer: {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "10px",
    maxHeight: 410,
    minHeight: 410,
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(196, 196, 196, 1)",
      borderRadius: "10px",
    },
  },
  font: {
    fontWeight: 600,
    fontSize: "13px",
  },
  top: {
    marginTop: "32px",
  },
  [theme.breakpoints.down("md")]: {
    tableContainer: {
      padding: 0,
    },
    font: {
      fontSize: "12px",
    },
  },
}));

export default function ReservationTable(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [openModal, setOpenModal] = useState(false);
  const [seledReservation, setSeledReservation] = useState(null);
  const activateReservation = async (id) => {
    setLoading(true);
    const { response } = await reservationServices.activateReservation(id);
    setLoading(false);
    setOpenToast(true);
    setMessage(response.message);
    props.getReservations();
  };
  return (
    <>
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {seledReservation && (
          <Grid
            container
            className={classes.top}
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography className={classes.font}>
                FECHA: {seledReservation.day}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.font}>
                HORA: {seledReservation.turno.start_time} -{" "}
                {seledReservation.turno.end_time}
              </Typography>
            </Grid>
            {seledReservation.status.name === "Pendiente" ? (
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <ButtonSpinner
                    disableElevation
                    onClick={() => {
                      setOpenModal(false);
                      activateReservation(seledReservation.token);
                    }}
                  >
                    Activar
                  </ButtonSpinner>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography className={classes.font}>
                  ESTATUS :{seledReservation.status.name}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Modal>
      <Spinner loading={loading} />
      <Toast
        open={openToast}
        message={message}
        close={() => {
          setOpenToast(false);
        }}
      />
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">
                <Typography className={classes.font}>FECHA</Typography>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: "80px" }}>
                <Typography className={classes.font}>HORA</Typography>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: "80px" }}>
                <Typography className={classes.font}>LUGAR</Typography>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: "150px" }}>
                <Typography className={classes.font}>ESTATUS</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell2 align="center"></StyledTableCell2>
            </TableRow>
          </TableBody>
          <TableBody className={classes.tableBody}>
            {props.reservations.map((reservation, index) => (
              <TableRow key={index}>
                <StyledTableCell align="center">
                  {reservation.day}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {reservation.turno.start_time} - {reservation.turno.end_time}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {reservation.place}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {reservation.status.name === "Pendiente" ? (
                    <ButtonSpinner
                      disableElevation
                      onClick={() => activateReservation(reservation.token)}
                    >
                      Activar
                    </ButtonSpinner>
                  ) : (
                    reservation.status.name
                  )}
                </StyledTableCell>
                {matches && (
                  <StyledTableCell align="center">
                    <VisibilityIcon
                      onClick={() => {
                        setSeledReservation(reservation);
                        setOpenModal(true);
                      }}
                    />
                  </StyledTableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                onChangePage={props.onChangePage}
                rowsPerPageOptions={[15]}
                count={props.count ? props.count : 0}
                rowsPerPage={15}
                page={props.page ? props.page - 1 : 0}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
