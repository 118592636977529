import * as React from "react";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import userServices from "../services/user-services";
import { Typography } from "@mui/material";
import { useEffect } from "react";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList({ onReservation }) {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [rightErrorMessaje, setRightErrorMessaje] = React.useState("");
  const [requestState, setRequestState] = React.useState({
    loading: false,
    data: null,
    response: null,
    error: null,
  });
  const [search, setSearch] = useState("");

  const searcher = (e) => {
    setSearch(e.target.value);
  };
  const results = !search
    ? left
    : left.filter(
        (dato) =>
          dato.firstName.toLowerCase().includes(search.toLocaleLowerCase()) ||
          dato.lastName.toLowerCase().includes(search.toLocaleLowerCase())
      );
  React.useEffect(() => {
    (async () => {
      try {
        setRequestState({ ...requestState, loading: true });
        const {
          response: { totalDocs },
        } = await userServices.getUsers(1, 1);
        const {
          response: { docs: users },
        } = await userServices.getUsers(1, totalDocs);
        setRequestState({ ...requestState, loading: false, error: null });

        setLeft(users);

        console.log("Users:", users); // Imprimir datos completos de los usuarios
      } catch (error) {
        alert("A ocurrido un error en la carga de usuarios :(");
        setRequestState({ ...requestState, loading: true, error });
      }
    })();
  }, []);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  // useEffect(() => {
  //   setRightErrorMessaje("");
  //   if (checked.length + right.length > 3) {
  //     setRightErrorMessaje("Solo puedes agregar un maximo de 3 usuarios a tu reserva!");
  //   }
  // }, [checked, right]);

  const handleCheckedRight = () => {
    setRightErrorMessaje("");
    if (checked.length + right.length < 2) {
      return setRightErrorMessaje(
        "Agregar un minimo de 2 Compañeros/as a tu reserva!"
      );
    }
    if (checked.length + right.length > 3) {
      return setRightErrorMessaje(
        "Solo puedes agregar un maximo de 3 Compañeros/as a tu reserva!"
      );
    }
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setRightErrorMessaje("");
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1, backgroundColor: "#2b4a3b", color: "white" }}
        title={title}
      />
      <Divider />
      <List
        sx={{
          width: "450px",
          height: "270px",
          bgcolor: "background.paper",
          overflow: "auto",
          marginTop: "10px",
          "@media (max-width:700px)": {
            height: "200px",
            width: "350px",
          },
        }}
        dense
        component="div"
        role="list"
      >
        {items
          .sort((a, b) => {
            return a.firstName.charCodeAt(0) - b.firstName.charCodeAt(0);
          })
          .map((value) => {
            const labelId = `transfer-list-all-item-${value._id}-label`;
            return (
              <ListItem
                key={value._id}
                role="listitem"
                button
                onClick={value.isSolvent && handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    disabled={!value.isSolvent}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={`${value.firstName} ${value.lastName}`}
                  primaryTypographyProps={{ sx: { display: "inline-block" } }}
                  secondary={!value.isSolvent && " - NO SOLVENTE"}
                  secondaryTypographyProps={{
                    sx: { color: "red", display: "inline-block" },
                    component: "span",
                  }}
                />
                <span>Hcp: {value.handicap}</span> {/* Agregar handicap */}
              </ListItem>
            );
          })}
        <ListItem height="220px" />
      </List>
    </Card>
  );

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{
          marginTop: "40px",
          "@media (max-width: 1220px)": {
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          },
        }}
      >
        <Grid item>
          {" "}
          {customList(
            <input
              value={search}
              onChange={searcher}
              type="text"
              placeholder="Buscar Usuario"
              className="form-control"
              style={{
                fontSize: "25px",
                width: "250px",
              }}
            />,
            results
          )}
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="center"
            sx={{
              "@media (max-width: 1200px)": {
                transform: "rotateX(180deg) rotate(270deg)",
              },
            }}
          >
            <Button
              sx={{
                my: 0.5,
                backgroundColor: "#2b4a3b",
                color: "#f9e189 !important",
                "@media (max-width: 1200px)": { fontSize: "25px" },
              }}
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{
                my: 0.5,
                backgroundColor: "#f9e189",
                color: "#2b4a3b !important ",
                "@media (max-width: 1200px)": { fontSize: "25px" },
              }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList("Acompañantes", right)}</Grid>
      </Grid>
      <Grid height="20px">
        {!requestState.loading && !rightErrorMessaje && (
          <Typography
            sx={{
              marginTop: "10px !important",
              fontSize: "12px !important",
              p: "0",
              m: "0",
              "@media (max-width: 1220px)": {
                marginTop: "5px",
                margirBotton: "5px",
              },
            }}
          >
            Solo puedes agregar un maximo de 3 Compañeros/as a tu reserva.
          </Typography>
        )}
        {requestState.loading && (
          <Typography
            sx={{
              fontSize: "12px !important",
              p: "0",
              m: "0",
              "@media (max-width: 1220px)": {
                marginTop: "5px",
                margirBotton: "5px",
              },
            }}
          >
            Cargando Usuarios...
          </Typography>
        )}
        {rightErrorMessaje && (
          <Typography
            sx={{
              marginTop: "10px !important",
              fontSize: "12px !important",
              p: "0",
              m: "0",
            }}
            color="red"
          >
            {rightErrorMessaje.toUpperCase()}
          </Typography>
        )}
      </Grid>
      <Button
        disabled={right.length === 0}
        style={{
          backgroundColor: "#2b4a3b",
          width: "110px",
          height: "35px",
          fontSize: "14px",
          marginTop: "15px",
          color: "white",
        }}
        onClick={() => onReservation({ users: right })}
      >
        Reservar
      </Button>
    </>
  );
}
