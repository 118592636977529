import React, { useState } from "react";
import {
  Popover,
  IconButton,
  MenuItem,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core/";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  menutext: {
    textAlign: "center",
    color: "#2b4a3b",
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

export default function BurgerMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  let history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const AuthMenu = () => (
    <div className={classes.menuAcount}>
      <MenuItem
        onClick={() => {
          handleClose();
          history.push(`/perfil`);
        }}
      >
        <Grid container alignItems="center">
          {/* <img
            src="./img/login-icon.svg"
            alt=""
            style={{ marginRight: "11px" }}
          /> */}
          <Typography className={classes.menutext}>Perfil</Typography>
        </Grid>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleClose();
          history.push(`/mis-reservas`);
        }}
      >
        {/* <img
          src="./img/sing-up-icon.svg"
          alt=""
          style={{ marginRight: "11px" }}
        /> */}
        <Typography className={classes.menutext}>Mis reservas</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleClose();
          history.push(`/contacto`);
        }}
      >
        {/* <img
          src="./img/sing-up-icon.svg"
          alt=""
          style={{ marginRight: "11px" }}
        /> */}
        <Typography className={classes.menutext}>Contacto</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleClose();
          props.logOut();
        }}
      >
        {/* <img
          src="./img/sing-up-icon.svg"
          alt=""
          style={{ marginRight: "11px" }}
        /> */}
        <Typography className={classes.menutext}>Cerrar sesión</Typography>
      </MenuItem>
    </div>
  );

  const NoAuth = () => (
    <div className={classes.menuAcount}>
      <MenuItem
        onClick={() => {
          handleClose();
          props.openLogin();
        }}
      >
        <Grid container alignItems="center">
          <Typography className={classes.menutext}>Inicio de Sesión</Typography>
        </Grid>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleClose();
          props.openRegister();
        }}
      >
        <Grid container alignItems="center">
          <Typography className={classes.menutext}>Registro</Typography>
        </Grid>
      </MenuItem>
    </div>
  );

  return (
    <>
      <IconButton
        onClick={handleClick}
        edge="start"
        className={classes.menuButton}
        aria-label="menu"
        style={{ marginLeft: "5px" }}
      >
        <MenuIcon style={{ fontSize: 40, color: "f9e18b" }} />
      </IconButton>
      <Popover
        id={"simple-menu"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {props.isAuth ? <AuthMenu /> : <NoAuth />}
      </Popover>
    </>
  );
}
