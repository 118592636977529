// const base = process.env.REACT_APP_API_URL;
const base = "https://la-salina-api.herokuapp.com/api/v1";
//const base = 'http://localhost:3001/api/v1'

const urls = {
  auth: {
    login: `${base}/login`,
    register: `${base}/register`,
    active: `${base}/validateaccount`,
    sendToken: `${base}/resendemail`,
  },
  paswordReset: {
    paswordResetRequest: `${base}/resetpassword`,
    paswordReset: `${base}/updatepassword`,
  },
  getUsers: `${base}/getUsers`,
  sports: {
    get: `${base}/sports`,
  },
  schedule: `${base}/places`,
  order: `${base}/orders`,
  reservation: `${base}/reservations`,
  hour: `${base}/hours`,
  dollar: `${base}/dolarprice`,
  contactMail: `${base}/send-contact-mail`
};

export default urls;
