import urls from "../urls";

const getHours = async () => {
  const request = await fetch(`${urls.hour}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};

const hourServices = Object.freeze({
  getHours,
});

export default hourServices;
