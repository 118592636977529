import React, { useState } from "react";
import { Modal, TextField, ButtonSpinner, Toast } from ".";
import { Grid, Typography, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { FormValidator } from "../helpers";
import { userServices } from "../services/";
// import { useHistory, useLocation } from "react-router-dom";
// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }
const useStyles = makeStyles((theme) => ({
  textTitle: {
    color: "rgba(15, 16, 49, 1)",
    fontSize: "16px",
    fontWeight: 700,
  },
  top: {
    marginTop: "32px",
  },
}));

export default function LoginFrom(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [seePassword, setSeePassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [validateAcount, setValidateAcount] = useState(false);
  // let history = useHistory();
  // let query = useQuery();
  const validator = new FormValidator([
    {
      field: "email",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese su correo electrónico",
    },
    {
      field: "password",
      method: "isEmpty",
      validWhen: false,
      message: "Ingrese su contraseña",
    },
  ]);
  const logIn = async (e) => {
    e.preventDefault();
    const validation = validator.validate({
      email,
      password,
    });
    setErrors(validation);
    if (validation.isValid) {
      setLoading(true);
      const { response, status } = await userServices.login({
        email,
        password,
      });
      setLoading(false);
      if (status === 200) {
        console.log(response.user);
        localStorage.setItem("token", response.token);
        localStorage.setItem(
          "user",
          `${response.user.firstName} ${response.user.lastName}`
        );
        localStorage.setItem("isSolvent", `${response.user.isSolvent}`);
        localStorage.setItem("handicap", `${response.user.handicap}`);
        props.handleClose();
        props.auth();
        // const redirect = query.get("redirect");
        setEmail("");
        setPassword("");
        // if (redirect) {
        //   if (redirect === "reservar") {
        //     history.push(`/reservar`);
        //   }
        // } else {
        //   history.push(`/perfil`);
        // }
      } else {
        if (response.error.code === 401) {
          setValidateAcount(true);
        } else {
          setOpenToast(true);
          setMessage(response.error.message);
        }
      }
    }
  };
  return (
    <Modal border {...props}>
      <Toast
        open={openToast}
        message={message}
        close={() => {
          setOpenToast(false);
        }}
      />

      <Grid container className={classes.top} justifyContent="center">
        <img
          src="logo.png"
          style={{ width: "80%", marginTop: "-75px", marginBottom: "-35px" }}
        />
        <Typography className={classes.textTitle} align="center">
          Inicio de sesión
        </Typography>
      </Grid>

      <Grid
        container
        className={classes.top}
        justifyContent="center"
        spacing={2}
        component="form"
        onSubmit={logIn}
      >
        <Grid item xs={12}>
          <TextField
            placeholder="Correo electrónico"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errors.email ? errors.email.isInvalid : false}
            helperText={errors.email ? errors.email.message : ""}
            type="email"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="Contraseña"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={seePassword ? "text" : "password"}
            error={errors.password ? errors.password.isInvalid : false}
            helperText={errors.password ? errors.password.message : ""}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSeePassword(!seePassword)}
                >
                  {!seePassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {validateAcount && (
            <Typography style={{ color: "rgba(15, 16, 49, 1)" }}>
              Tu cuenta no esta{" "}
              <strong
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props.handleClose();
                  props.openResendToken();
                }}
              >
                validada
              </strong>
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ color: "rgba(15, 16, 49, 1)" }}>
            ¿No tienes cuenta?{" "}
            <strong
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.handleClose();
                props.openSingup();
              }}
            >
              Regístrate
            </strong>
          </Typography>
          <Typography style={{ color: "rgba(15, 16, 49, 1)" }}>
            Olivide mi{" "}
            <strong
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.handleClose();
                props.openResetPasswordRequest();
              }}
            >
              contraseña
            </strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <ButtonSpinner loading={loading} type="submit">
              INICIA SESIÓN
            </ButtonSpinner>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
