import React from "react";
import { Grid, Avatar, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  card: {
    background: "#0C5704",
    padding: "10px 20px",
  },
  avatar: {
    width: 80,
    height: 80,
    fontSize: "2.25rem",
    fontWeight: 900,
    backgroundColor: "rgba(255, 190, 0, 0.6)",
  },
  name: {
    fontWeight: 900,
    color: "#FFF",
  },
}));

export default function ProfileCard(props) {
  const classes = useStyles();
  const user = localStorage.getItem("user");
  function getAvatar(userName) {
    const user = userName.split(" ");
    return `${user[0].charAt(0)}${user[1].charAt(0)}`;
  }
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.card}
    >
      <Grid item xs={5}>
        {user && <Avatar className={classes.avatar}>{getAvatar(user)}</Avatar>}
      </Grid>
      <Grid item xs={7}>
        <Typography className={classes.name}>Bienvenido</Typography>
        <Typography className={classes.name}>{user}</Typography>
      </Grid>
    </Grid>
  );
}
