import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography, Container } from "@material-ui/core";
import {
  BannerLayOut,
  Avatar,
  // ButtonSpinner,
  // ProfileCard,
  // ReservationsCard,
} from "../components";
import { makeStyles } from "@material-ui/core/styles";
// import { useHistory } from "react-router-dom";
import { reservationServices } from "../services";
import { LoaderContext } from "../contexts";

// const useStyles = makeStyles((theme) => ({
//   textPrimary: {
//     color: "#fff",
//     fontSize: "65px",
//     fontWeight: 300,
//     lineHeight: "70px",
//   },
//   textPrimaryBold: {
//     color: "rgba(85, 4, 3, 1)",
//     fontSize: "65px",
//     fontWeight: 900,
//     lineHeight: "70px",
//   },
//   font: {
//     fontFamily: ["Roboto Slab", "serif"].join(","),
//   },
// }));

export default function Profile() {
  const classes = useStyles();
  // const history = useHistory();
  const { loadingActive, loadingDisabled } = useContext(LoaderContext);
  const [reservations, setReservations] = useState([]);

  console.log({ reservations });

  useEffect(() => {
    const getUserReservations = async () => {
      try {
        loadingActive();
        const token = localStorage.getItem("token");
        const { response, status } =
          await reservationServices.getUserReservations(
            token,
            1,
            5,
            "approved"
          );
        if (status === 200) {
          setReservations(response.docs);
        }
      } catch (error) {
        console.log({ error });
      } finally {
        loadingDisabled();
      }
    };
    getUserReservations();
  }, [loadingActive, loadingDisabled]);

  return (
    // <Grid container alignItems="center" justifyContent="center">
    //   <Grid
    //     container
    //     alignItems="center"
    //     justifyContent="center"
    //     style={{ paddingBottom: "10px" }}
    //   >
    <BannerLayOut src="./img/banner.jpg">
      <Container maxWidth="md">
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <Typography
              color="primary"
              variant="h2"
              align="center"
              paragraph
              className={`${classes.bold} ${classes.font}`}
            >
              PERFIL
            </Typography>
            <Avatar />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              color="primary"
              variant="h2"
              align="center"
              paragraph
              className={`${classes.bold} ${classes.font}`}
            >
              HISTORIAL
            </Typography>
            <div
              style={{
                backgroundColor: "#FFF",
                display: "flex",
                marginBottom: 40,
              }}
            >
              <div
                style={{
                  width: 20,
                  backgroundColor: "#FFBE00",
                  marginRight: 20,
                }}
              />
              <Typography
                variant="h5"
                className={classes.font}
                style={{ lineHeight: 2 }}
              >
                HISTORIAL
              </Typography>
            </div>
            <div
              style={{
                backgroundColor: "#FFF",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: 20,
                  backgroundColor: "#FFBE00",
                }}
              />
              {reservations.length < 1 ? (
                <Typography
                  align="center"
                  style={{ flex: 1, lineHeight: 3 }}
                  className={classes.font}
                  variant="h6"
                >
                  No hay reservas en tu historial
                </Typography>
              ) : (
                <div style={{ margin: "0 20px", width: "100%" }}>
                  {
                    <ul className={`${classes.reservations} ${classes.font}`}>
                      {reservations.map((reservation, index) => (
                        <li key={index}>
                          {reservation.date} -{/* Cancha{" "} */}{" "}
                          {reservation.sportInfo.sport} -{" "}
                          {reservation.hourRef.start}
                        </li>
                        // <li>17/Septiembre - Cancha 1 - 4:00 PM</li>
                      ))}
                    </ul>
                  }
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        {/* <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Typography
                  align="center"
                  className={`${classes.textPrimaryBold} ${classes.font}`}
                >
                  PERFIL
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ marginTop: "70px", marginBottom: "70px" }}
              >
                <ProfileCard />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <ButtonSpinner
                    style={{
                      fontSize: "34px",
                      fontWeight: 900,
                      lineHeight: "42px",
                      paddingRight: "40px",
                      paddingLeft: "40px",
                    }}
                    onClick={() => history.push("/reservar")}
                  >
                    RESERVAR
                  </ButtonSpinner>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Typography
                  align="center"
                  className={`${classes.textPrimaryBold} ${classes.font}`}
                >
                  HISTORIAL
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ marginTop: "70px" }}>
                <ReservationsCard />
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      </Container>
    </BannerLayOut>
    //   </Grid>
    // </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 800,
  },
  font: {
    fontFamily: ["Roboto Slab", "serif"].join(","),
  },
  reservations: {
    listStyle: "none",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    gap: 10,
    "& > li": {
      backgroundColor: "#EAEAEA",
      padding: 4,
      textAlign: "center",
    },
  },
}));
