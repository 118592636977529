import React, { useEffect } from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { LayOut } from "./components";
// Importar componentes en comun como navbars
import {
  Landing,
  Profile,
  Reservations,
  MakeReservation,
  PaymentMethods,

  Contacto,
  // Resume,
} from "./screens/";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// import las pantallas desde screens
export default function Routes() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <LayOut>
                <ScrollToTop />
                <Landing />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/perfil"
            render={() => (
              <LayOut>
                <ScrollToTop />
                <Profile />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/mis-reservas"
            render={() => (
              <LayOut>
                <ScrollToTop />
                <Reservations />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/reservar"
            render={() => (
              <LayOut>
                <ScrollToTop />
                <MakeReservation />
              </LayOut>
            )}
          />

          <Route
            exact
            path="/contacto"
            render={() => (
              <LayOut>
                <ScrollToTop />
                <Contacto />
              </LayOut>
            )}
          />
          <Route
            exact
            path="/perfil"
            render={() => (
              <LayOut>
                <ScrollToTop />

                <Profile />
              </LayOut>
            )}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}
