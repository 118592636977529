import urls from "../urls";

const addReservation = async ({schedules, token, users}) => {
  const request = await fetch(`${urls.reservation}/add`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ schedules, users }),
  });
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};

const getUserReservations = async (token, page, limit, filter = "") => {
  const request = await fetch(
    `${urls.reservation}/user?page=${page}&limit=${limit}&filter=${filter}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};

const cancel = async (id) => {
  const request = await fetch(`${urls.reservation}/cancel`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({ _id: id})
  });
  if (!request.ok) throw { ...request };
  const response = await request.json();
  return {
    status: request.status,
    response,
  };
};
const reservationServices = Object.freeze({
  addReservation,
  getUserReservations,
  cancel
});

export default reservationServices;
